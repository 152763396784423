export default function() {
  const get = path => this.$path(path, this.$store.state)
  const geti18n = (key, index = 1) => this.$tc('floorplan', index)
  return [
    {
      component: 'Section',
      condition: get('Residential.item.name'),
      props: {
        width: 'medium',
        id: 'section-header',
        class: 'section-header',
        style: '',
      },
      blocks: {
        default: {
          component: 'Grid',
          props: {
            columns: '1.5:1',
            gap: '2rem 5rem',
          },
          blocks: {
            default: [
              {
                component: 'Flex',
                props: {
                  flexDirection: 'column',
                },
                blocks: {
                  default: [
                    {
                      component: 'Floorplan',
                      props: {
                        class: 'residential__cover',
                      },
                      data: get('Residential.item'),
                    },
                  ],
                },
              },
              {
                component: 'Summary',
                props: { class: 'residential__summary' },
                data: get('Residential.item'),
              },
            ],
          },
        },
      },
    },
    {
      component: 'Section',
      condition: get('Residential.item.name'),
      props: {
        width: 'medium',
        id: 'section-info',
        class: 'section-info',
        style: 'padding-top:2rem;',
      },
      blocks: {
        default: {
          component: 'Grid',
          props: {
            columns: '1.5:1',
            gap: '2rem 5rem',
          },
          blocks: {
            default: [
              {
                component: 'Flex',
                props: {
                  flexDirection: 'column',
                },
                blocks: {
                  default: [
                    {
                      component: 'Text',
                      props: {
                        class: 'residential__description',
                      },
                      data: {
                        content: `<h2>${get('Residential.item.displayName') ||
                          get('Residential.item.name')}</h2>${get('Residential.item.description') ||
                          ''}`,
                      },
                    },

                    {
                      component: 'Brochure',
                      condition: (get('Residential.item.project.media.brochure') || []).length,
                      data: get('Residential.item.project.media.brochure'),
                      props: {
                        style: 'padding-top: auto;',
                        placement: 'top',
                      },
                    },
                  ],
                },
              },
              {
                component: 'Openhouse',
                props: {
                  class: 'residential__openhouse',
                  style: 'color: var(--body-color);',
                },

                data: {
                  content: get('Residential.item.openhouses'),
                },
                condition: () => {
                  if (!this.item) return false
                  if (get('Residential.item.openhouses.length')) return true
                  return !KvassConfig.get('siteSettings.hideNoOpenhouse')
                },
              },
            ],
          },
        },
      },
    },

    {
      component: 'Section',
      condition:
        get('Residential.item.shop.published') && !KvassConfig.get('siteSettings.hideShop'),
      props: {
        width: 'medium',
        id: 'section-shop-intro',
        class: 'section-shop-intro',
      },
      blocks: {
        default: [
          {
            component: 'ScrollAnchor',
            data: 'shop',
          },
          {
            component: 'ShopIntro',
            data: {
              residential: get('Residential.item'),
            },
          },
        ],
      },
    },

    {
      component: 'Section',
      props: {
        width: 'medium',
        id: 'section-flatfinder',
        class: 'section-flatfinder',
        style: 'padding-bottom:1rem;',
      },
      blocks: {
        default: [
          {
            component: 'ScrollAnchor',
            data: 'flatfinder',
          },
          {
            component: 'Flatfinder',
            data: {
              flatfinders: get('Residential.item.project.flatfinders'),
            },
          },
        ],
      },
    },

    {
      component: 'Section',
      props: {
        width: 'medium',
        id: 'section-residentials',
        class: 'section-residentials',
        style: 'padding-top:1rem',
      },
      blocks: {
        default: [
          {
            component: 'ScrollAnchor',
            data: 'residentials',
          },
          {
            component: 'Residentials',
            data: {
              project: get('Residential.item.project.id'),
            },
            props: {
              showTotalPrice: !get('Project.item.customFields.project-settings.hide-total-price'),
              title: get('Project.item.customFields.title-overrides.residentials'),
            },
          },
        ],
      },
    },

    {
      component: 'Section',
      condition: [
        ...(get('Residential.item.media.attachments') || []),
        ...(get('Residential.item.project.media.attachments') || []),
      ].length,
      props: {
        width: 'medium',
        id: 'section-attachments',
        class: 'section-attachments',
      },
      blocks: {
        default: [
          {
            component: 'ScrollAnchor',
            data: 'attachments',
          },
          {
            component: 'Attachments',
            data: [
              ...(get('Residential.item.media.attachments') || []),
              ...(get('Residential.item.project.media.attachments') || []),
            ],
          },
        ],
      },
    },

    {
      component: 'Section',
      props: {
        width: 'full',
        class: 'section-gallery',
      },
      blocks: {
        default: [
          {
            component: 'Gallery',
            props: {
              variant: 'grid',
              columns:
                get('Residential.item.media.gallery.length') === 1
                  ? 1
                  : (get('Residential.item.media.gallery.length') || 1) % 2 === 0
                  ? 2
                  : 3,
            },

            data: {
              content: get('Residential.item.media.gallery'),
            },
          },
        ],
      },
    },

    {
      component: 'Section',
      props: {
        width: 'full',
        id: 'section-map',
        class: 'section-map',
        style: 'padding-bottom:0;',
      },
      blocks: {
        default: [
          {
            component: 'ScrollAnchor',
            data: 'map',
          },
          {
            component: 'Map',
            props: {
              aspectRatio: '21/9',
            },
            data: {
              ...get('Residential.item.project.address'),
              coordinates: get('Residential.item.project.address.location.coordinates'),
            },
          },
        ],
      },
    },
    {
      component: 'Section',
      condition: get('Residential.item.project.id'),
      props: {
        width: 'medium',
        id: 'section-contact',
        class: 'section-contact',
        style: 'background-color: var(--light-grey);',
      },
      blocks: {
        default: [
          {
            component: 'ScrollAnchor',
            data: 'lead',
          },
          {
            component: 'Grid',
            props: {
              gap: '2rem 8rem',
              columns: '1:2',
            },
            blocks: {
              default: [
                {
                  component: 'Roles',
                  props: {},
                  data: {
                    content: get('Residential.item.project.roles.salesman'),
                  },
                },
                {
                  component: 'Lead',
                  props: {
                    options: {
                      fields: ['!title'],
                    },
                  },
                  data: {
                    reference: {
                      ref: get('Residential.item.project.id'),
                      onModel: 'Project',
                    },
                    scope: { onModel: 'Residential', ref: get('Residential.item.id') },
                  },
                  blocks: {
                    top: [
                      {
                        component: 'Text',
                        data: {
                          content: get('Residential.item.project.customFields.lead-top-content'),
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ]
}
